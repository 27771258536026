import React, { ReactElement, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../layouts/Layout/Layout'
import Seal from '../../assets/images/09-2024-SealofRegistration-AvidaLandCorp.webp'
import './PrivacyPolicy.scss'
import ArticleLayout, {
  RecentArticleTypes,
} from '../../layouts/ArticleLayout/ArticleLayout'
import TERMS_AND_CONDITIONS_NAVIGATION from '../../data/terms-and-conditions-navigation.json'
import Seo from '../../components/Seo/Seo'
import getContent from '../../services/getContent'

type PrivacyPolicyPropTypes = {
  data: {
    privacyPolicyData: {
      title: string
      body: string
    }
    recentArticles: {
      nodes: RecentArticleTypes[]
    }
  }
}

const personalDataDirect1 = [
  {
    category: 'Personal Identification',
    examples: 'Name, gender, civil status',
    id: '1',
  },
  {
    category: 'Contact Information',
    examples: 'Address, telephone or mobile numbers, email address',
    id: '2',
  },
  {
    category: 'Job Application Data',
    examples:
      'Employment history, educational background, résuméand pictures sent with it, compensation and benefits, pictures included in',
    id: '3',
  },
  {
    category:
      'Any other information that you voluntarily decide to share with Avida Land Corp.',
    examples:
      'Inquiries,   feedback,   opinions,   reviews,   comments, uploaded   files,   information   provided   for   our   due diligence process',
    id: '4',
  },
]

const personalDataDirect2 = [
  {
    category: 'Personal Identification',
    examples:
      'Name, gender, civil status, birthdate, government-issued ID, citizenship, occupation, spouse’s name',
    id: '1',
  },
  {
    category: 'Contact Information',
    examples: 'Address, contact numbers, email address',
    id: '2',
  },
  {
    category: 'Financial Information',
    examples:
      'Credit card details, issuing bank, source of funding, monthly income range',
    id: '3',
  },
  {
    category:
      'Any other information that you voluntarily decide to share with Avida Land Corp.',
    examples:
      'Inquiries,   feedback,   opinions,   reviews,   comments, uploaded   files,   information   provided   for   our   due diligence process',
    id: '4',
  },
]

const personalDataAutomatic = [
  {
    category: 'Device Information',
    examples:
      'IP address, hardware model, IMEI number and other unique device identifiers, operating system version',
    id: '6',
  },
  {
    category: 'Location Information',
    examples:
      'Your location (derived from your IP address, Bluetooth beacons or identifiers,    or    other    location-based    products    or    features technologies) that may be collected when you enable location-based features',
    id: '7',
  },
  {
    category: 'Information  about  your  visit and use of our website',
    examples:
      'Time,  duration,  manner  of  use  of  our  products  and  services,  or products and services connected to ours.',
    id: '8',
  },
]

const PrivacyPolicy = ({
  data: {
    privacyPolicyData,
    recentArticles: { nodes: recentArticles },
  },
}: PrivacyPolicyPropTypes): ReactElement => {
  const [title] = useState(privacyPolicyData.title || 'Privacy Notice')

  return (
    <Layout>
      <Seo
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/privacy-notice/`,
          description: getContent(privacyPolicyData.body),
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, privacy policy',
        }}
        title="Privacy Notice"
      />
      <div className="privacy-policy">
        <ArticleLayout
          asideNavItems={TERMS_AND_CONDITIONS_NAVIGATION}
          breadcrumbs={[
            {
              label: 'Privacy Notice',
            },
          ]}
          recentArticles={recentArticles}
        >
          <article className="privacy-policy-article">
            <h2 className="privacy-policy-article-title-main">
              Avida Land Corp. Privacy Notice
            </h2>
            <p className="privacy-policy-article-description">
              Effective Date: November 15, 2022
            </p>
            <p className="privacy-policy-article-description">
              Welcome to Avida Land’s Corporate website, an online platform
              managed by Ayala Land, Inc. (referred to as the terms “we,” “us,”
              and “our” in this Notice). The term “you” and its derivatives
              refer to customers, vendors, partners, employees, associates, and
              other stakeholders of Ayala Land, Inc. Here’s how we protect your
              data privacy:
            </p>
            <h4 className="privacy-policy-article-title">
              <strong>Our Role in Your Data Privacy</strong>
            </h4>
            <p className="privacy-policy-article-description">
              When you use{' '}
              <strong style={{ color: '#000', cursor: 'pointer' }}>
                the Avida Land website (<a href="/">www.avidaland.com</a>)
              </strong>{' '}
              this notice applies to you. Please read and understand it before
              providing us with your personal data.
            </p>
            <p>
              <b>Our Responsibilities</b>
            </p>
            <p className="privacy-policy-article-description">
              We process information pursuant to the Ayala Land Group (“Group”)
              Privacy Statement. To know more about our general principles and
              how we process your personal data within the Group, please visit{' '}
              <a href="https://www.ayalaland.com.ph/privacy-statement/">
                https://www.ayalaland.com.ph/privacy-statement/
              </a>
              .
              <br />
              <br />
              We may revise this Privacy Notice from time to time as we add new
              features and purposes of data processing, or as laws change that
              may affect our company’s services. When we make material changes
              to this Privacy Notice, we will post the date it was last revised.
            </p>
            <p>
              <b>Your Responsibilities</b>
            </p>
            <p className="privacy-policy-article-description">
              <ul>
                <li>Read and understand this Privacy Notice</li>
                <li>
                  Ensure that any information that you provide us is complete,
                  accurate and up-to-date
                </li>
                <li>
                  If you provide us with personal data about other people, we
                  will use that information only for the specific purpose for
                  which it was provided to us. By submitting the information,
                  you warrant and confirm that you have the right to authorize
                  us to process it on your behalf in accordance with this
                  Privacy Notice.
                </li>
              </ul>
              .
            </p>
            {/* --When and How We Collect Data-- */}
            <h4 className="privacy-policy-article-title">
              <strong>When and How We Collect Data</strong>
            </h4>
            <p className="privacy-policy-article-description">
              From the first moment you engage through our website, we are
              collecting data. Here’s when and how we do this:
              <ul>
                <li>You use our digital platform;</li>
                <li>
                  You interact with our sales or customer care agents,
                  reservation officers and specialists through chat facility;
                </li>
                <li>
                  You provide personal information in relation to inquiries,
                  requests, and complaints;
                </li>
                <li>
                  You subscribe and/or respond to surveys, promotions, and other
                  marketing and sales initiatives;
                </li>
                <li>You submit a job application</li>
                <li>
                  You provide personal information, information related to the
                  property you are purchasing from us, and your financial
                  information whenever you use the &quot;Reserve Now&quot;
                  button to pay for your reservation fee, down payment, or
                  amortizations via auto-debit online through the AQWIRE website
                  (an online payment facility owned and operated by QWIKWIRE
                  Corporation).
                </li>
              </ul>
              .
            </p>
            {/* --What types of data we collect from you-- */}
            <h4 className="privacy-policy-article-title">
              <strong>What types of data we collect from you</strong>
            </h4>
            <p>
              When you inquire about our products and service in this website
              through <b>Chat With Us</b>, <b>Contact Us</b>,{' '}
              <b>Be Part of Our Corporate Team</b> , the Company collects and
              processes the following personal information that you provide:
            </p>
            <div className="privacy-policy-article-content">
              <div className="content">
                <p>
                  <b>
                    <i>Personal data you provide us directly</i>
                  </b>
                </p>
                <hr />
                <div className="grid-container">
                  <div className="grid-item-title">CATEGORIES</div>
                  <div className="grid-item-title">EXAMPLES</div>
                  {personalDataDirect1.map((data) => (
                    <>
                      <div key={data.id} className="grid-item-content">
                        {data.category}
                      </div>
                      <div key={data.id} className="grid-item-content">
                        {data.examples}
                      </div>
                    </>
                  ))}
                </div>
                <p>
                  When you pay for the property that you are purchasing pursuant
                  to a reservation agreement, contract to sell, or any other
                  contract with us through the <b>“Reserve Now”</b> button and
                  the AQWIRE website, the Company collects and processes the
                  following personal information that you provide:
                </p>
                <hr />
                <div className="grid-container">
                  <div className="grid-item-title">CATEGORIES</div>
                  <div className="grid-item-title">EXAMPLES</div>
                  {personalDataDirect2.map((data) => (
                    <>
                      <div key={data.id} className="grid-item-content">
                        {data.category}
                      </div>
                      <div key={data.id} className="grid-item-content">
                        {data.examples}
                      </div>
                    </>
                  ))}
                </div>
                <p>
                  <b>
                    <i>Personal data we collect automatically:</i>
                  </b>
                </p>
                <p>
                  In addition to the information that you provided us directly,
                  we may collect information sent to us by your computer, mobile
                  phone, or other access device when you visit or use our
                  websites or applications, subscribe to our newsletters, or
                  otherwise interact with us through our digital channels. For
                  example, we may collect:
                </p>
                <hr />
                <div className="grid-container">
                  <div className="grid-item-title">CATEGORIES</div>
                  <div className="grid-item-title">EXAMPLES</div>
                  {personalDataAutomatic.map((data) => (
                    <>
                      <div key={data.id} className="grid-item-content">
                        {data.category}
                      </div>
                      <div key={data.id} className="grid-item-content">
                        {data.examples}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <p>
              <b>
                <i>
                  Information we may aggregate from different sources to your
                  profile
                </i>
              </b>
            </p>
            <p className="privacy-policy-article-description">
              If you have informed us that you wish to receive personalized
              direct marketing communications, we might aggregate data from
              different sources (both internally and externally) to have a
              better understanding of your preference and interests and be able
              to serve you with more relevant communications. You can always
              object to these activities by opposing this. In particular, you
              can always opt-out from receiving marketing-related emails by
              following the unsubscribe instructions provided in each email.
            </p>
            <p>
              <b>Disclosing personal information other than yours</b>
            </p>
            <p className="privacy-policy-article-description">
              When you disclose to us personal information of another person
              (e.g., your dependent, spouse, children, and/or parents), you
              attest that consent has been obtained from that person to disclose
              and process his/her personal information in accordance with this
              privacy notice.
              <br />
              <br />
              If the Personal Information was provided by a minor or one who is
              not legally capable of entering into contractual transactions
              without parental or guardian consent, the parent or legal guardian
              shall be responsible in providing the consent, and in correcting
              any information given or informing us that the information should
              be disregarded or deleted.
            </p>
            {/* --How and why we use your personal data-- */}
            <h4 className="privacy-policy-article-title">
              <strong>How and why we use your personal data</strong>
            </h4>
            <p className="privacy-policy-article-description">
              Under the Data Protection Act, we can only use your personal data
              for certain specified purposes and where we have a legal basis to
              do so. Here are the reasons for which we process your data:
              <ul>
                <li>
                  Providing you with product and services that you have
                  requested, including customer support.
                </li>
                <li>
                  Enhancing your customer experience by delivering product and
                  services that match your preference and needs;
                </li>
                <li>
                  Communicating relevant products and services, advisories, and
                  responses to your queries, requests, job application, and
                  other concerns;
                </li>
                <li>
                  Processing of information for statistical, analytical, and
                  research purposes
                </li>
                <li>
                  Receiving and recording payments for products and services you
                  are purchasing from us.
                </li>
              </ul>
            </p>
            <p className="privacy-policy-article-description">
              Just in case you requested or opted information about our products
              or services, you may unsubscribe from them any time by clicking
              the unsubscribe link in the promotional emails, following the
              steps provided in the promotional text messages or replying to the
              email indicating that you no longer wish to receive any email from
              us.
            </p>
            <h4 className="privacy-policy-article-title">
              <strong>Other uses by nature of our dealings</strong>
            </h4>
            <p className="privacy-policy-article-description">
              Aside from the general uses mentioned above, depending on your
              dealings with us, we may use your personal information to serve
              the following purposes:
            </p>
            <p>
              <b>
                <i>When you inquire about or purchase property from us</i>
              </b>
            </p>
            <p className="privacy-policy-article-description">
              <ul>
                <li>Conduct of appropriate due diligence checks;</li>
                <li>
                  Registering your inquiry and addressing any follow-up calls;
                </li>
                <li>
                  Preventing, detecting, and investigating a crime (including
                  fraud and money-laundering);
                </li>
              </ul>
            </p>
            <p>
              <b>
                <i>
                  When you use any of our digital platforms, including mobile
                  apps
                </i>
              </b>
            </p>
            <p className="privacy-policy-article-description">
              <ul>
                <li>
                  Giving our third-party service providers the necessary
                  information to implement our rewards programs and access to
                  any advanced features of our digital platform, as applicable;
                </li>
                <li>
                  Assessing your eligibility for certain rewards and programs,
                  and informing you of products, services, events, promotions,
                  and initiatives that may be of interest to you;
                </li>
                <li>
                  Addressing your queries, requests, feedback, changes to our
                  terms of service, and matters relating to the operation of
                  your account, as applicable.
                </li>
              </ul>
              You may unsubscribe from our offers or marketing materials at any
              time by clicking the unsubscribe link in the promotional emails or
              by following the steps provided in the promotional text messages.
            </p>
            <p>
              <b>
                <i>When you apply for employment or become our employee</i>
              </b>
            </p>
            <p className="privacy-policy-article-description">
              <ul>
                <li>
                  Conducting appropriate assessments, background investigation,
                  reference checks, pre- employment medical examination and
                  interviews;
                </li>
              </ul>
            </p>
            {/* --Your Privacy Rights-- */}
            <h4 className="privacy-policy-article-title">
              <strong>Your Privacy Rights</strong>
            </h4>
            <p className="privacy-policy-article-description">
              You can exercise your rights as a data subject by sending us an
              email at{' '}
              <a href="mailto: avidadataprivacy@avidaland.com">
                avidadataprivacy@avidaland.com
              </a>{' '}
              . When you make a request, note that such a request is subject to
              certain conditions. We may also charge you for any of these
              services
            </p>
            <p>
              <b>You have the right to access information we hold about you</b>
            </p>
            <p className="privacy-policy-article-description">
              You may instruct us to provide you with any personal information
              we hold about you, subject to providing us with appropriate
              evidence of your identity.
              <ul>
                <li>
                  We may withhold personal information that you request to the
                  extent permitted by law.
                </li>
                <li>
                  You may instruct us at any time not to process your personal
                  information for marketing purposes.
                </li>
                <li>
                  You will be asked to expressly agree in advance to our use of
                  your personal information for marketing purposes, or we will
                  provide you with an opportunity to opt out of the use of your
                  personal information for the same purpose.
                </li>
              </ul>
            </p>
            <p>
              <b>
                You can object to us using your data for profiling you or making
                automated decisions about you
              </b>
            </p>
            <p className="privacy-policy-article-description">
              We may use your data to determine whether we should let you know
              information that might be relevant to you (for example, tailoring
              emails sent to you based on your interests or behavior).
              Otherwise, the only circumstances in which we will do this is to
              provide service to you.
            </p>
            <p>
              <b>You have the right to port your data.</b>
            </p>
            <p className="privacy-policy-article-description">
              We will give you a copy of your data in a mutually acceptable
              format. We will not do so to the extent that this involves
              disclosing data about any other individual.
            </p>
            <p>
              <b>You have the right for your data to be erased.</b>
            </p>
            <p className="privacy-policy-article-description">
              You can do this by asking us to suspend, withdraw, remove or
              destroy your personal data, if it is no longer necessary for us to
              hold the data for purposes of your use of Avida.
            </p>
            <p>
              <b>
                You have the right to lodge a complaint regarding our use of
                your data
              </b>
            </p>
            <p className="privacy-policy-article-description">
              Please tell us first, so we have a chance to address your
              concerns. If we fail in this, you can address any complaint to the
              National Privacy Commission.
            </p>
            <p>
              <b>You have the right to damages</b>
            </p>
            <p className="privacy-policy-article-description">
              You have the right to damages arising from inaccurate, incomplete,
              false, unlawfully obtained, or unauthorized use of your personal
              data by Ayala Land, Inc.
            </p>
            {/* --How secure is the data we collect?-- */}
            <h4 className="privacy-policy-article-title">
              <strong>How secure is the data we collect?</strong>
            </h4>
            <p className="privacy-policy-article-description">
              We do our best to ensure that your personal data is protected and
              your privacy is preserved.
              <br />
              <br />
              We use physical, technical, and organizational safeguards to
              protect your personal data against loss, theft, and unauthorized
              access, disclosure, copying, use, or modification. These practices
              include:
              <ul>
                <li>
                  Use of secured servers behind advanced threat protection
                  appliances, firewalls, encryption and other security tools.
                </li>
                <li>
                  Use of standard encryption tools such as SSL (Secure Socket
                  Layer) to help protect personal data and to keep information
                  collected secure.
                </li>
                <li>
                  Website/Application security testing conducted by a third
                  party prior to it being launched and when major changes have
                  been applied.
                </li>
              </ul>
              In addition, we limit access to your personal data to those
              employees, agents, contractors and other third parties who are
              qualified and authorized to process them. All such persons,
              wherever they are located, are required to protect the
              confidentiality and privacy of your personal data in a manner
              consistent with our privacy policies and practices.
            </p>
            <p>
              <b>And please remember:</b>
            </p>
            <p className="privacy-policy-article-description">
              <ul>
                <li>
                  You provide personal data at your own risk. Unfortunately, no
                  data transmission is guaranteed to be 100% secure
                </li>
                <li>
                  You are responsible for your username and password. Keep them
                  secret and safe!
                </li>
                <li>
                  If you believe your privacy has been breached, please contact
                  us immediately by sending us an email at
                  avidadataprivacy@avidaland.com.
                </li>
              </ul>
            </p>
            {/* --How long do we store your data?-- */}
            <h4 className="privacy-policy-article-title">
              <strong>How long do we store your data?</strong>
            </h4>
            <p className="privacy-policy-article-description">
              We shall retain your personal data obtained via our Contact Us, Be
              Part of Our Team, Ayala Land Group Data Privacy forms, and Chat
              facilities for a period of two (2) years from the fulfillment of
              the declared purposes or allowable criteria for its processing;
              unless we believe that these may be relevant to any ongoing or
              prospective legal proceedings in order to establish, exercise, and
              defend our legal rights (including providing information to others
              for the purposes of fraud prevention and reducing credit risk).
            </p>
            <p className="privacy-policy-article-description">
              Personal data you have shared via AQWIRE website shall be retained
              only for a period of five (5) years from the date of your
              transaction, except where specific laws and/or regulations require
              a different retention period, in which case, the longer retention
              period shall be observed. Financial data and documents you provide
              which indicate taxable transactions shall be preserved for ten
              (10) years pursuant to prevailing Bureau of Internal Revenue (BIR)
              regulations.
            </p>
            <p className="privacy-policy-article-description">
              Thereafter, your personal information shall be disposed or
              discarded in a secure manner that would prevent further
              processing, unauthorized access, or disclosure to any other party
              or the public. Specifically, all personal data in electronic form
              will be deleted from our databases, following the retention period
              stated above.
            </p>
            {/* --Third parties who process your data-- */}
            <h4 className="privacy-policy-article-title">
              <strong>Third parties who process your data</strong>
            </h4>
            <p>
              <b>Fulfillment of Legitimate and Business Purposes</b>
            </p>
            <p className="privacy-policy-article-description">
              In fulfillment of the stated purpose/s that you consented to, we
              may disclose your personal information to the following parties:
              <ul>
                <li>Our employees and officers;</li>
                <li>Professional advisers such as lawyers and auditors;</li>
                <li>
                  Members of our group of companies or our subsidiaries and
                  affiliates;
                </li>
                <li>
                  Our holding company Ayala Corporation and all its subsidiaries
                  and affiliates;
                </li>
                <li>Our joint venture and alliance partners; and</li>
                <li>Our technology and service providers.</li>
              </ul>
              And in order to comply with legal and statutory compliance, we may
              disclose your personal data:
              <ul>
                <li>
                  To the extent that we are required to do so by applicable
                  laws, rules, and regulations;
                </li>
                <li>
                  In connection with any ongoing or prospective legal
                  proceeding;
                </li>
                <li>
                  To any person who we reasonably believe may apply to a court
                  or other competent authority for disclosure of such personal
                  information where, in our reasonable opinion, such court or
                  authority would be reasonably and is likely to order the
                  disclosure of such personal information;
                </li>
              </ul>
              Any company/entity with which we share information about you for
              the above purposes is contractually required to comply with
              confidentiality standards, undertake to respect any individual’s
              right to privacy, and comply with the Data Privacy Act. We also
              require that these organizations use this information only for our
              purposes, and follow our reasonable directions with respect to the
              use and protection of this information. This includes
              organizations which handle or obtain personal information as
              technical and service providers for Ayala, Land, Inc. who, in
              addition to the above measures, undergo our due diligence that
              includes their data protection practices.
            </p>
            {/* --Privacy Policies of Third-party Websites and Digital Platforms-- */}
            <h4 className="privacy-policy-article-title">
              <strong>
                Privacy Policies of Third-party Websites and Social Media
                Platforms
              </strong>
            </h4>
            <p className="privacy-policy-article-description">
              Our websites contain hyperlinks and details of third-party
              websites, including social media platforms and our payment service
              provider AQWIRE. We have no control over and are not responsible
              for the privacy policies and practices of third parties.
              <br />
              <br />
              We have no control over and are not responsible for the privacy
              policies and practices of third parties. We therefore advise you
              to study their privacy policies before providing them with any
              personal information. Once you leave our website, you should
              likewise check the applicable privacy policy of the third-party
              website to determine how they will handle any information they
              collect from you.
            </p>
            {/* --Cookies and Similar Technologies-- */}
            <h4 className="privacy-policy-article-title">
              <strong>Cookies and Similar Technologies</strong>
            </h4>
            <p className="privacy-policy-article-description">
              We use cookies to provide you with a better and more personal user
              experience. Unless you adjust your browser settings to refuse
              cookies, we will issue cookies when you interact with Ayala Land,
              Inc. Please note that where third parties use cookies, we have{' '}
              <b style={{ color: '#000' }}>no control</b> over how those third
              parties use those cookies.
            </p>
            <div className="privacy-policy-article-divider">
              <div>
                <p>
                  <b>How can I block cookies?</b>
                </p>
                <p className="privacy-policy-article-description">
                  You can block cookies by activating a setting on your browser
                  so that cookies are not accepted. You can also delete cookies
                  through your browser settings. Each browser&#39;s website
                  should contain instructions on how you can do this.
                  <br />
                  <br />
                  If you use your browser settings to disable, reject, or block
                  cookies (including essential cookies), certain parts of our
                  website will not function fully. In some cases, our website
                  may not be accessible at all.
                </p>
                {/* --How to Contact Us-- */}
                <h4 className="privacy-policy-article-title">
                  <strong>How to Contact Us</strong>
                </h4>
                <p className="privacy-policy-article-description">
                  For comments, questions, or requests relating to this privacy
                  policy, or complaints in relation to your rights under this
                  privacy policy, you may get in touch with the Ayala Land,
                  Inc.’s Data Protection Officer by by sending us an email at
                  avidadataprivacy@avidaland.com
                </p>
              </div>
              <img
                src={Seal}
                alt="Privacy Policy Seal"
                className="privacy-policy-article-seal-of-registration"
              />
            </div>
          </article>
        </ArticleLayout>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy

export const pageQuery = graphql`
  query {
    privacyPolicyData: privacyPolicy {
      ...PrivacyPolicyFields
    }
    recentArticles: allNewsAndEvents(limit: 3) {
      nodes {
        ...NewsAndEventsCardFields
      }
    }
  }
`
